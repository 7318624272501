import Modal from '@mozaic-ds/freemarker/js/modules/_modal.js';
import { MODAL } from '../addtocartmodule/add-to-cart.constants';
export default class ModalBuilder {
  constructor() {
    this.errorModal = new Modal({
      classContent: MODAL.ERROR_SELECTOR
    });
    this.successModal = new Modal({
      classContent: MODAL.SUCCESS_SELECTOR
    });
  }
  display(configuration) {
    const {
      type,
      content,
      action,
      refreshAfterClose
    } = configuration;
    const modalType = type === 'success' ? this.successModal : this.errorModal;
    modalType.modal.querySelector(`.${MODAL.CONTENT_SELECTOR}`).innerHTML = content;
    if (action) {
      modalType.modal.querySelector(`.${MODAL.FOOTER_SELECTOR}`).addEventListener('click', action, {
        once: true
      });
    }
    modalType.open();
    const seeCartButton = modalType.modal.querySelector(`.${MODAL.SEE_CART_SELECTOR}`);
    if (seeCartButton) seeCartButton.addEventListener('click', () => {
      window.location.href = modalType.modal.querySelector(`.${MODAL.REDIRECT_SELECTOR}`).value;
    }, {
      once: true
    });
    if (refreshAfterClose) {
      const refreshButtons = modalType.modal.querySelectorAll(`.${MODAL.CLOSE_SELECTOR}`);
      refreshButtons.forEach(button => {
        button.addEventListener('click', () => {
          window.location.reload();
        });
      });
    }
  }
}