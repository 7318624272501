const submitRoute = {
  page: '/services/product-informations-page',
  modal: '/services/product-informations',
  layer: '/services/addToCart'
};
const globalFormField = ['reflm', 'quantity', 'offerId', 'contextCode', 'simulationId', 'buttonLocation'];
export const ADD_TO_CART_CONFIGURATION = {
  customer: {
    submitRoute,
    checkRoute: '/services/add-to-cart',
    authorizedFormField: [...globalFormField, 'addedToCart', 'tempoRedirect', 'collaboratorRedirect']
  },
  collaborator: {
    submitRoute,
    checkRoute: '/services/collaborator/add-to-cart',
    authorizedFormField: [...globalFormField, 'addedToCart', 'tempoRedirect', 'collaboratorRedirect']
  },
  editorial: {
    submitRoute: {
      page: '/cart/services/addToCartApp/addToCartPage',
      modal: '/cart/services/addToCart/addToCartInMarcopolo'
    },
    authorizedFormField: globalFormField
  }
};