import { AddToCartMode } from './add-to-cart-mode.class';
import HttpModule from '../tools/http.module';
import { ADD_TO_CART_CONFIGURATION } from './add-to-cart.configuration';
import { ADD_TO_CART, HTTP_HEADERS, MODAL } from './add-to-cart.constants';
import { enableDisabledAllATCButtons } from '../tools/utils.class';
export default class AddToCartModalMode extends AddToCartMode {
  constructor(resourceType, mode) {
    super(resourceType);
    this.resourceType = resourceType;
    this.mode = mode;
    this.check = false;
  }
  updateCart(parameters) {
    var _a;
    if (parameters.check) {
      this.atcButtonLocation = (_a = parameters.products[0]) === null || _a === void 0 ? void 0 : _a.atcButtonLocation;
      this.check = true;
      const headersAddToCart = this.getHeadersForAddToCart();
      this.checkCartService.checkCart({
        data: parameters.products,
        headers: headersAddToCart,
        success: this.successCheckCart.bind(this),
        error: this.errorResponse.bind(this)
      });
    } else {
      this.getProductInformationForAddToCart(parameters.products);
    }
  }
  successCheckCart(response) {
    const responseObject = JSON.parse(response);
    if (responseObject) {
      if (responseObject.error) {
        this.showErrorModal(responseObject.error);
      } else if (responseObject.products) {
        this.dispatchCartUpdateEvent(ADD_TO_CART.EVENT_ADDED);
        this.getProductInformationForAddToCart(responseObject);
      }
    }
  }
  getProductInformationForAddToCart(response) {
    const route = ADD_TO_CART_CONFIGURATION[this.resourceType].submitRoute.modal;
    const httpModule = new HttpModule(route);
    const params = {
      body: JSON.stringify(response),
      headers: new Headers(HTTP_HEADERS),
      success: this.successGetProductInformation.bind(this),
      error: this.errorResponse.bind(this)
    };
    httpModule.post(params);
  }
  successGetProductInformation(response) {
    const responseHasError = response.includes(`data-cerberus="${ADD_TO_CART.DATA_CERBERUS_ATC}"`);
    const typeModal = responseHasError && !this.check ? ADD_TO_CART.RESPONSE_ERROR : ADD_TO_CART.RESPONSE_SUCCESS;
    const modalDetailsConfig = {
      type: typeModal,
      content: response,
      refreshAfterClose: this.mode === MODAL.MODE.CART
    };
    this.modalBuilder.display(modalDetailsConfig);
    enableDisabledAllATCButtons(false);
    lm.lazyload.observe();
    this.dispatchCartUpdateEvent(ADD_TO_CART.EVENT_UPDATED);
    this.dispatchTrackingAtcModal();
  }
  dispatchTrackingAtcModal() {
    const trackingElement = this.modalBuilder.successModal.modal.querySelector('script.js-atcModalDataTracking');
    if (trackingElement) {
      const trackingDataParsed = JSON.parse(trackingElement.innerHTML);
      const cdlProductsAdded = trackingDataParsed.filter(value => value.name === 'cdl_added_products')[0].value;
      this.dispatchTrackingAtc(cdlProductsAdded);
    }
  }
}