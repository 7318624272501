import CheckCartService from './check-cart.service';
import ModalBuilder from '../tools/modal-builder.class';
import { ADD_TO_CART_CONFIGURATION } from './add-to-cart.configuration';
import { ADD_TO_CART, ErrorMessage, HTTP_HEADERS, RESOURCE_TYPE } from './add-to-cart.constants';
import { dispatchAtcTracking, enableDisabledAllATCButtons } from '../tools/utils.class';
import { DATA_LAYER } from '../datalayer/datalayer.constants';
export class AddToCartMode {
  constructor(resourceType) {
    this.resourceType = resourceType;
    const {
      checkRoute
    } = ADD_TO_CART_CONFIGURATION[this.resourceType];
    this.modalBuilder = new ModalBuilder();
    this.checkCartService = new CheckCartService(checkRoute);
  }
  setCookieCartInSessionStorage() {
    lm.localStorageLM.set(ADD_TO_CART.EVENT_UPDATED, 'true', 1, 'sessionStorage', 'LMData');
  }
  getHeadersForAddToCart() {
    let headers = HTTP_HEADERS;
    if (this.resourceType === RESOURCE_TYPE[1]) {
      const customerCartValue = this.getCustomerIdFromCookie();
      const customerCart = customerCartValue ? customerCartValue : 'no customer';
      headers = Object.assign(Object.assign({}, headers), {
        'X-Customer-Cart': customerCart
      });
    }
    return headers;
  }
  dispatchTrackingAtc(cdlAddedProducts) {
    if (cdlAddedProducts) {
      const orderIdCookie = lm.cookie.get(DATA_LAYER.ORDER_ID_COOKIE_KEY);
      const customerUidCookie = lm.cookie.get(DATA_LAYER.CUSTOMER_UUID_COOKIE_KEY);
      const cartId = customerUidCookie || orderIdCookie;
      const trackingData = Object.assign({
        cdl_added_products: cdlAddedProducts,
        cdl_cart_id: cartId
      }, this.atcButtonLocation ? {
        button_location: `${window.tc_vars['cdl_page_subtype']} - ${this.atcButtonLocation}`
      } : {});
      dispatchAtcTracking({
        target: window,
        eventType: 'cdl_add_to_cart',
        trackingData
      });
    }
  }
  getCustomerIdFromCookie() {
    var _a;
    const customerSalesCookie = lm.cookie.get(ADD_TO_CART.CUSTOMER_SALES_COOKIE_KEY);
    let customerBannerObject = {};
    try {
      customerBannerObject = JSON.parse(customerSalesCookie ? atob(customerSalesCookie) : '{}');
    } catch (error) {
      console.error('Error parsing customerSales cookie', error);
    }
    return (_a = customerBannerObject.id) !== null && _a !== void 0 ? _a : 'no customer';
  }
  errorResponse(product, lightMode) {
    if (!lightMode) {
      const modalDetailsConfig = {
        type: 'error',
        content: ErrorMessage['default-error']
      };
      this.modalBuilder.display(modalDetailsConfig);
    }
    enableDisabledAllATCButtons(false);
    this.dispatchCartUpdateEvent(ADD_TO_CART.EVENT_ERROR, {
      detail: {
        product
      }
    });
  }
  dispatchCartUpdateEvent(eventName, eventData) {
    document.dispatchEvent(new CustomEvent(eventName, eventData));
  }
  showErrorModal(errorObject) {
    const contentMessage = ErrorMessage[errorObject.code] ? ErrorMessage[errorObject.code] : ErrorMessage['default-error'];
    const modalDetails = {
      type: 'error',
      content: contentMessage
    };
    enableDisabledAllATCButtons(false);
    if (errorObject.code === 'missing-mandatory-customer') {
      modalDetails.action = this.checkCartService.reAuth;
    }
    this.modalBuilder.display(modalDetails);
    this.dispatchCartUpdateEvent(ADD_TO_CART.EVENT_UPDATED);
  }
}