import { AddToCartMode } from './add-to-cart-mode.class';
import FormBuilder from '../tools/form-builder.class';
import { ADD_TO_CART_CONFIGURATION } from './add-to-cart.configuration';
import { ADD_TO_CART } from './add-to-cart.constants';
export default class AddToCartPageMode extends AddToCartMode {
  constructor(resourceType) {
    super(resourceType);
    this.resourceType = resourceType;
    const formAction = ADD_TO_CART_CONFIGURATION[this.resourceType].submitRoute.page;
    this.formBuilder = new FormBuilder(ADD_TO_CART.FORM_METHOD, formAction);
    this.authorizedField = ADD_TO_CART_CONFIGURATION[this.resourceType].authorizedFormField;
  }
  updateCart(parameters) {
    var _a;
    if (parameters.setCookie) {
      this.setCookieCartInSessionStorage();
    }
    const headersAddToCart = this.getHeadersForAddToCart();
    if (parameters.check) {
      this.atcButtonLocation = (_a = parameters.products[0]) === null || _a === void 0 ? void 0 : _a.atcButtonLocation;
      this.checkCartService.checkCart({
        data: parameters.products,
        headers: headersAddToCart,
        success: this.successCheckCart.bind(this),
        error: this.errorResponse.bind(this)
      });
    } else {
      this.createFormAddToCart(parameters.products);
    }
  }
  successCheckCart(response) {
    const responseObject = JSON.parse(response);
    if (responseObject) {
      if (responseObject.error) {
        this.showErrorModal(responseObject.error);
      } else if (responseObject.products) {
        this.dispatchCartUpdateEvent(ADD_TO_CART.EVENT_ADDED);
        this.createFormAddToCart(responseObject);
      }
    }
  }
  createFormAddToCart(data) {
    this.createInputsProductsForFormCart(data);
    this.formBuilder.addFormInBody();
    this.formBuilder.submitForm();
  }
  createInputsProductsForFormCart(data) {
    data.products.forEach(product => {
      product.tempoRedirect = data.tempoRedirect;
      product.collaboratorRedirect = data.collaboratorRedirect;
      this.authorizedField.forEach(field => {
        if (product[field] !== null) {
          this.formBuilder.addInput({
            type: 'hidden',
            name: field,
            value: product[field]
          });
        }
      });
    });
  }
}