import FormBuilder from '../tools/form-builder.class';
import HttpModule from '../tools/http.module';
import { ADD_TO_CART } from './add-to-cart.constants';
export default class CheckCartService {
  constructor(route) {
    this.httpModule = new HttpModule(route);
  }
  checkCart(parameters) {
    this.httpModule.post({
      body: JSON.stringify(parameters.data),
      headers: new Headers(parameters.headers),
      success: parameters.success,
      error: parameters.error
    });
  }
  reAuth() {
    const formAction = ADD_TO_CART.RE_AUTH_ROUTE;
    const formBuilder = new FormBuilder(ADD_TO_CART.FORM_METHOD, formAction);
    formBuilder.addInput({
      name: 'callback',
      type: 'text',
      value: window.location.pathname
    });
    formBuilder.addFormInBody();
    formBuilder.submitForm();
  }
}