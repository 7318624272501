import AddToCartModule from '../addtocartmodule/add-to-cart.module';
import { RESOURCE_TYPE } from '../addtocartmodule/add-to-cart.constants';
class AddToCartCustomer extends lm.Composant {
  constructor(id) {
    super(id);
  }
  init() {
    const addToCartModule = new AddToCartModule(RESOURCE_TYPE[0]);
    addToCartModule.init();
  }
}
lm.DOMReady(() => {
  const addToCartCustomerInstance = new AddToCartCustomer('cartcdp');
  addToCartCustomerInstance.init();
});