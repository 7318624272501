export const ADD_TO_CART = {
  ACCORDIONS_SELECTOR: '#component-addtocartpageapp .js-accordion',
  DISPLAY_PAGE_MODE: 'PAGE_CDP',
  DISPLAY_MODAL_DEFAULT_MODE: 'POPIN_CDP',
  DISPLAY_MODAL_CART_MODE: 'POPIN_CART_CDP',
  DISPLAY_LAYER_CART_MODE: 'LAYER_CDP',
  INPUT_ATC_MODE: 'atc-mode',
  FORM_METHOD: 'POST',
  RE_AUTH_ROUTE: '/services/reauth',
  EVENT_UPDATED: 'cartUpdated',
  EVENT_SUCCESS: 'cartSuccess',
  EVENT_ERROR: 'cartError',
  EVENT_ADDED: 'cartProductAdded',
  RESPONSE_SUCCESS: 'success',
  RESPONSE_ERROR: 'error',
  DATA_CERBERUS_ATC: 'ATC_NOT_ADDED_PRODUCTS_LIST',
  CUSTOMER_SALES_COOKIE_KEY: 'elo.customerSales'
};
export const RESOURCE_TYPE = ['customer', 'collaborator', 'editorial'];
export const HTTP_HEADERS = {
  'Content-Type': 'application/json;charset=UTF-8'
};
export const MODAL = {
  SUCCESS_SELECTOR: 'popin-cart--success',
  ERROR_SELECTOR: 'popin-cart--error',
  CONTENT_SELECTOR: 'popin-cart__content',
  FOOTER_SELECTOR: 'mc-modal__footer button:last-of-type',
  SEE_CART_SELECTOR: 'js-atc-popin-see-cart',
  REDIRECT_SELECTOR: 'js-cart-redirect-url',
  CLOSE_SELECTOR: 'js-modal-close',
  MODE: {
    DEFAULT: 'default',
    CART: 'cart'
  }
};
export const LAYER = {
  SELECTOR: 'js-atc-layer',
  RESPONSE_SELECTOR: 'js-response-layer',
  ADDED_CONTAINER_SELECTOR: 'js-act-container',
  LOADER_SELECTOR: 'js-atc-layer-loader'
};
export const HIDDEN_CSS_CLASS = 'kl-hidden';
export const ErrorMessage = {
  'inconsistent-place-type': 'Si è verificato un errore',
  'invalid-quantity': 'Questa quantità non può essere aggiunta al carrello, modifica la quantità e riprova',
  'too-many-lines': 'È stato raggiunto il numero massimo di prodotti nel carrello.',
  'sample-quantity-exceeded': 'Questo campione è già presente nel carrello. È possibile aggiungere un solo campione di questo prodotto.',
  'creation-need-valid-offer': 'Questo prodotto non può essere aggiunto al carrello',
  'invalid-customer': 'Il tuo carrello è scaduto. Riprova',
  'inconsistent-customer-user': 'Il carrello è scaduto, riprovare',
  'customer-order-not-found': 'Il carrello è scaduto, riprovare',
  'missing-mandatory-customer': 'C\'è stato un errore.',
  'stage-not-allowed': 'Il carrello è scaduto, riprovare',
  'default-error': 'Si è verificato un errore durante l\'inserimento dei prodotti nel carrello'
};