export default class FormBuilder {
  constructor() {
    let method = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'POST';
    let action = arguments.length > 1 ? arguments[1] : undefined;
    this.form = document.createElement('form');
    this.form.method = method;
    this.form.action = action;
  }
  addInput(options) {
    const {
      type,
      name,
      value
    } = options;
    if (value !== null && name && type) {
      const input = document.createElement('input');
      input.type = type;
      input.name = name;
      input.value = value;
      this.form.appendChild(input);
    }
  }
  addFormInBody() {
    document.body.appendChild(this.form);
  }
  submitForm() {
    this.form.submit();
  }
}