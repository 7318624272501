import AccordionModule from '../accordion/accordion.module';
import AddToCartDisplayModeFactory from './add-to-cart-display-mode.factory';
import { ADD_TO_CART } from './add-to-cart.constants';
import { enableDisabledAllATCButtons, isAllAtcButtonsDisabled } from '../tools/utils.class';
import { ADD_TO_CART_CONFIGURATION } from './add-to-cart.configuration';
export default class AddToCartModule {
  constructor(type) {
    this.type = type;
    this.displayModeValue = this.getAddToCartDisplayModeValue();
  }
  init() {
    this.initializeAddToCartAccordion();
    this.addToCartDisplayModeModule = this.addToCartDisplayMode();
    this.listen();
  }
  listen() {
    document.addEventListener('cartUpdate', event => {
      if (event && event.detail) {
        const products = event.detail.listProducts;
        if (!isAllAtcButtonsDisabled()) {
          enableDisabledAllATCButtons(true);
          document.cookie = `CART_PRODUCT=${products[0].reflm}; path=/`;
          const check = !!ADD_TO_CART_CONFIGURATION[this.type].checkRoute;
          this.addToCartDisplayModeModule.addToCartModeModule.updateCart({
            products,
            setCookie: true,
            check
          });
        }
      }
    });
  }
  initializeAddToCartAccordion() {
    const accordionAddToCart = new AccordionModule(ADD_TO_CART.ACCORDIONS_SELECTOR);
    accordionAddToCart.init();
  }
  getAddToCartDisplayModeValue() {
    const actPageEnableElement = document.querySelector(`[name="${ADD_TO_CART.INPUT_ATC_MODE}"]`);
    return actPageEnableElement.value;
  }
  addToCartDisplayMode() {
    return new AddToCartDisplayModeFactory({
      type: this.type,
      displayMode: this.displayModeValue
    });
  }
}