import Accordion from '@mozaic-ds/freemarker/js/modules/_accordion.js';
export default class AccordionModule {
  constructor(selectorElements) {
    this.accordions = document.querySelectorAll(selectorElements);
  }
  init() {
    if (this.accordions) {
      this.accordions.forEach(accordion => new Accordion(accordion));
    }
  }
}