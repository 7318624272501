import AddToCartPageMode from './add-to-cart-page-mode.class';
import AddToCartModalMode from './add-to-cart-modal-mode.class';
import { ADD_TO_CART, MODAL } from './add-to-cart.constants';
import AddToCartLayerMode from './add-to-cart-layer-mode.class';
export default class AddToCartDisplayModeFactory {
  constructor(configurationDisplayMode) {
    this.configurationDisplayMode = configurationDisplayMode;
    this.displayModeInstance();
  }
  displayModeInstance() {
    switch (this.configurationDisplayMode.displayMode) {
      case ADD_TO_CART.DISPLAY_PAGE_MODE:
        this.addToCartModeModule = new AddToCartPageMode(this.configurationDisplayMode.type);
        break;
      case ADD_TO_CART.DISPLAY_MODAL_DEFAULT_MODE:
        this.addToCartModeModule = new AddToCartModalMode(this.configurationDisplayMode.type, MODAL.MODE.DEFAULT);
        break;
      case ADD_TO_CART.DISPLAY_MODAL_CART_MODE:
        this.addToCartModeModule = new AddToCartModalMode(this.configurationDisplayMode.type, MODAL.MODE.CART);
        break;
      case ADD_TO_CART.DISPLAY_LAYER_CART_MODE:
        this.addToCartModeModule = new AddToCartLayerMode(this.configurationDisplayMode.type);
        break;
      default:
        throw 'Unknown mode of add to cart';
    }
  }
}