import Layer from '@mozaic-ds/freemarker/js/modules/_layer.js';
import { AddToCartMode } from './add-to-cart-mode.class';
import HttpModule from '../tools/http.module';
import { ADD_TO_CART_CONFIGURATION } from './add-to-cart.configuration';
import { ADD_TO_CART, HIDDEN_CSS_CLASS, LAYER } from './add-to-cart.constants';
import { dispatchAtcTracking, enableDisabledAllATCButtons } from '../tools/utils.class';
export default class AddToCartLayerMode extends AddToCartMode {
  constructor(resourceType) {
    super(resourceType);
    this.resourceType = resourceType;
    this.check = false;
    this.layer = new Layer({
      classContent: LAYER.SELECTOR
    });
    this.addedProductContainer = this.layer.layer.querySelector(`.${LAYER.ADDED_CONTAINER_SELECTOR}`);
    this.responseAddedCartContainer = this.addedProductContainer.querySelector(`.${LAYER.RESPONSE_SELECTOR}`);
    this.loader = this.addedProductContainer.querySelector(`.${LAYER.LOADER_SELECTOR}`);
  }
  updateCart(parameters) {
    var _a, _b;
    const lightMode = (_a = parameters.products[0]) === null || _a === void 0 ? void 0 : _a.lightMode;
    if (!lightMode) {
      this.layer.open();
      this.dispatchTrackingOpenedLayer();
      this.closeOtherOpenedLayers();
      this.responseAddedCartContainer.innerHTML = '';
      this.loader.classList.remove(HIDDEN_CSS_CLASS);
    }
    this.atcButtonLocation = (_b = parameters.products[0]) === null || _b === void 0 ? void 0 : _b.atcButtonLocation;
    this.dispatchCartUpdateEvent(ADD_TO_CART.EVENT_ADDED);
    let route = ADD_TO_CART_CONFIGURATION[this.resourceType].submitRoute.layer;
    if (lightMode) {
      route += '/light';
    }
    const headersAddToCart = this.getHeadersForAddToCart();
    const httpModule = new HttpModule(route);
    const params = {
      body: JSON.stringify(parameters.products),
      headers: headersAddToCart,
      success: response => this.successCheckCart(response, parameters.products[0], lightMode),
      error: () => this.errorCheckCart(parameters.products[0], lightMode)
    };
    httpModule.post(params);
  }
  successCheckCart(response, product, lightMode) {
    let inError = false;
    if (response.charAt(0) === '{') {
      const responseObject = JSON.parse(response);
      if (responseObject) {
        if (responseObject.code) {
          inError = true;
          this.dispatchCartUpdateEvent(ADD_TO_CART.EVENT_ERROR, {
            detail: {
              product
            }
          });
          if (!lightMode) {
            setTimeout(() => {
              this.showErrorModal(responseObject);
              this.layer.close();
            }, 500);
          }
        }
      }
    }
    if (!inError) {
      this.loader.classList.add(HIDDEN_CSS_CLASS);
      if (!lightMode) {
        this.insertNewHtmlContent(this.responseAddedCartContainer, response);
      }
      enableDisabledAllATCButtons(false);
      this.dispatchCartUpdateEvent(ADD_TO_CART.EVENT_UPDATED);
      this.dispatchCartUpdateEvent(ADD_TO_CART.EVENT_SUCCESS, {
        detail: {
          product
        }
      });
      if (lightMode) {
        this.dispatchTrackingAtc([Object.assign({
          quantity: product === null || product === void 0 ? void 0 : product.quantity
        }, (product === null || product === void 0 ? void 0 : product.dataLayerProductInfo) || {})]);
      } else {
        this.dispatchTrackingAtcLayer();
      }
      lm.lazyload.observe();
    }
  }
  errorCheckCart(product, lightMode) {
    this.errorResponse(product, lightMode);
    if (!lightMode) {
      this.layer.close();
    }
  }
  closeOtherOpenedLayers() {
    const othersOpenedLayers = document.querySelectorAll('div[id^=\'js-layer\']:not([aria-hidden=\'true\']):not([class*=\'js-atc-layer\'])');
    if (othersOpenedLayers.length > 0) {
      othersOpenedLayers.forEach(layer => {
        layer.setAttribute('aria-hidden', 'true');
        layer.setAttribute('tabindex', '-1');
        const dialogContainer = layer.querySelector('.mc-layer__dialog');
        if (dialogContainer !== null && dialogContainer !== undefined) {
          dialogContainer.classList.remove('is-open');
        }
      });
    }
  }
  insertNewHtmlContent(prmDomElement, prmHtml) {
    var _a;
    const dom = new DOMParser().parseFromString(`<template>${prmHtml}</template>`, 'text/html').head;
    prmDomElement.appendChild((_a = dom.firstElementChild) === null || _a === void 0 ? void 0 : _a.content);
  }
  dispatchTrackingAtcLayer() {
    const trackingElement = document.querySelector('script.js-atcDataTracking');
    if (trackingElement) {
      const trackingDataParsed = JSON.parse(trackingElement.innerHTML);
      this.dispatchTrackingAtc(trackingDataParsed.addedProducts);
    }
  }
  dispatchTrackingOpenedLayer() {
    const trackingData = {
      event_name: 'popin.display',
      event_data: {
        feature_name: 'add-to-cart',
        popin_name: 'add to cart layer'
      }
    };
    dispatchAtcTracking({
      target: window,
      eventType: 'cdl_event',
      trackingData
    });
  }
}